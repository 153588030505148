angular.module('eOpti.app.tasks').controller('detailTaskController', [
    '$scope',
    '$http',
    '$state',
    '$location',
    'task',
    'offer',
    'breadcrumb',
    'memory',
    'taskService',
    'marketingService',
    function($scope,
              $http,
              $state,
              $location,
              task,
              offer,
              breadcrumb,
              memory,
              taskService,
              marketingService) {

        $scope.task = task.data

        window.task = $scope.task

        taskService.data = $scope.task

        $scope.offer = offer ? offer.data : null

        if($scope.offer) {
            breadcrumb.data = [{
                last: 2
            }, {
                functions: 'patient',
                args: [$scope.task.patient.id, 'state']
            }, {
                functions: 'offer',
                args: [$scope.task.patient.id, $scope.offer.id, 'state']
            }, {
                name: 'tasks.breadcrumb.OFFER_TASK',
                id: $state.params.id
            }, {
                name: 'tasks.card.SUMMARY'
            }]
        } else {
            breadcrumb.data = [{
                last: 2
            }, {
                name: 'tasks.breadcrumb.TASKS'
            }, {
                functions: 'tasks',
                args: null
            }, {
                functions: 'task',
                args: [$state.params.id]
            }, {
                name: 'tasks.card.SUMMARY'
            }]
        }

        $scope.updateOffer = offer => $scope.offer = offer

        $scope.increment = 0

        $scope.incrementUp = () => {
            $scope.increment += 1
        }

        $scope.marketing = marketingService.data
        $scope.marketing.reset()

        $scope.forceSave = () => {
            $scope.task.fake_data = 'fake_data'
        }

        $scope.rateSave = () => {
            $scope.task.rate_save = 'rate_save'
        }

        if($state.params.refresh) {
            $state.go('app.task.detail', {id: $scope.task.id, refresh: false}, {notify: false})

            setTimeout(() => {
                $scope.forceSave()
                $scope.$emit('success', 'tasks.action.duplicate.UPDATEPRICES')
            }, 1000)
        }

        memory.rememberObject('tasks', {
            id: $scope.task.id,
            name: $scope.task.patientName,
            is_offer: !!$scope.offer
        })

        if(!$scope.offer) {
            breadcrumb.data[2].args = $scope.task.statusName

            $scope.$watch('task.statusName', nVal => {
                breadcrumb.data[2].args = nVal
            })
        }

        $scope.changeLogs = {
            info: false
        }

        $scope.registerChange = () => {
            $scope.changeLogs.info = true
        }

        $scope.notes_length = 0

        $scope.updateNotesLength = note_length => {
            $scope.notes_length = note_length
        }

        if($scope.task.frame.state == '2') {
            $scope.task.frame.partName2 = $scope.task.frame.partName
        }

        let prefix_card = 'tasks.card.'

        $scope.tab = {
            summary: prefix_card + 'SUMMARY',
            powers: prefix_card + 'POWERS',
            frame: prefix_card + 'FRAME',
            lenses: prefix_card + 'LENSES',
            lenses_to_compare: prefix_card + 'LENSES_TO_COMPARE',
            payment: prefix_card + 'PAYMENT',
            previouslenses: prefix_card + 'PREVIOUSLENSES',
            ipad: prefix_card + 'IPAD',
            left: prefix_card + 'LEFT',
            history: prefix_card + 'HISTORY',
            notes: prefix_card + 'NOTES'
        }

        $scope.cardActive = {}

        $scope.setActive = hash => {
            $scope.activeTab = $scope.tab[hash]

            angular.forEach($scope.tab, (value, key) => {
                $scope.cardActive[key] = false
            })

            $scope.cardActive[hash] = true

            breadcrumb.changeLast({name: $scope.activeTab})

            $location.hash(hash)
        }

        $scope.showSummary = () => {
            return !!($scope.task.frame.partName && ($scope.task.rightLens.partName || $scope.task.leftLens.partName))
        }

        if($location.hash()) {
            if($location.hash() === 'summary' && !$scope.showSummary()) {
                $location.hash('frame')
            }

            $scope.setActive($location.hash())
        } else {
            $scope.setActive($scope.showSummary() ? 'summary' : 'frame')
        }

        $scope.edit = () => {
            return $http.put('api/task/edit/' + ($scope.offer ? 'offer' : 'task'), $scope.task)
        }

        $scope.pendingTask = false

        $scope.$watch('task._autosave._state', function (nStatus, oStatus) {
            if (nStatus !== 'init' && nStatus !== oStatus) {
                $scope.pendingTask = true
            } else {
                if ($scope.pendingTask) {
                    if(typeof $scope.task.reload != 'undefined' && $scope.task.reload) {
                        $scope.$emit('notify', {
                            message: 'tasks.notify.error.RELOAD',
                            status: 'danger',
                            timeout: 10000
                        })
                    }
                }

                $scope.pendingTask = false
            }
        })

        $scope.updatePending = bool => {
            $scope.pendingTask = bool
        }

        $scope.sumTaskExtra = lenses => {
            let price = 0

            angular.forEach($scope.task.services, service => {
                if((lenses && service.hoya_option_id) || (!lenses && !service.hoya_option_id)) {
                    price += service.pivot.price
                }
            })

            return price
        }

        $scope.extension = {}
        // TODO: zobaczyć czemu w zakładce ramka i w podsumowaniu nie widać na czerwono nadpisanego produktu
        //  (jest po odświeżeniu)

        $scope.$watch('task.frame.productIdOverwrite', nVal => {
            if(nVal) {
                $scope.extension = {
                    id: $scope.task.frame.productIdOverwrite,
                    name: $scope.task.frame.productNameOverwrite
                }
            } else {
                $scope.extension = {}
            }
        })

        $scope.frameOverwriteWasUpdated = product => {
            if(typeof product !== 'undefined') {
                $scope.extension = product
            }

            $scope.forceSave()
        }

        $scope.payment_pending = false
        $scope.updatePaymentPending = p => {
            $scope.payment_pending = p
        }

        if(!$scope.task.registry.discountValue) {
            $scope.task.registry.discountValue = 0
        }

        $scope.$watch('task.registry.discountType', newValue => {
            if(newValue === 'none') {
                $scope.task.registry.discountValue = 0
            }
        })
    }
])
